/**
 * @deprecated Use isScreen.ts
 *
 * libs/shared/v2/hooks/useBreakpoints/isScreen.ts
 *
 * import { isScreen } from '@/libs/v2/hooks/useBreakpoints';
 */
const isScreen =
  (breakpoints) =>
  (...sizes) =>
    sizes.some((size) => breakpoints[size]);

export default isScreen;
