import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, NakedButton } from '@qga/roo-ui/components';
import Image from '@/shared/components/Image';
import getInLength from '../../utils/getInLength';
import Swipeable from '../Swipeable';
import Controller from './components/Controller';

const StyledBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;

  transition: all 250ms ease-out 0s;

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `};
`;

const withInRange = (current, index, length, n) =>
  (current - n <= index && index <= current + n) ||
  (current === 0 && index > length - 1 - n) ||
  (current === length - 1 && index < 0 + n);

export const DumbCarousel = ({
  images,
  currentIndex,
  onPrevious,
  onNext,
  onClick,
  ...props
}) => (
  <Swipeable onSwipedLeft={onPrevious} onSwipedRight={onNext}>
    <Box position="relative" height="100%" width="100%">
      {images.map(
        (image, i) =>
          withInRange(currentIndex, i, images.length, 1) && (
            <StyledBox
              key={i} // eslint-disable-line react/no-array-index-key
              as={onClick ? NakedButton : 'div'}
              onClick={onClick}
              data-testid="IMAGE_WRAPPER"
              visible={currentIndex === i}
            >
              <Image
                {...props} // eslint-disable-line react/jsx-props-no-spreading
                src={image.src}
                alt={image.caption}
                height="100%"
                width="100%"
              />
            </StyledBox>
          ),
      )}
      {images.length > 1 && (
        <Controller onPrevious={onPrevious} onNext={onNext} />
      )}
    </Box>
  </Swipeable>
);

DumbCarousel.defaultProps = {
  fit: 'cover', // eslint-disable-line react/default-props-match-prop-types
  onClick: undefined,
};

DumbCarousel.propTypes = {
  onClick: PropTypes.func,
  currentIndex: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
};

const Carousel = ({ images, ...props }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0);
  }, [images]);

  const currentIndex = getInLength(index, images.length);

  return (
    <DumbCarousel
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      currentIndex={currentIndex}
      onPrevious={() => setIndex((v) => v - 1)}
      onNext={() => setIndex((v) => v + 1)}
      images={images}
    />
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
};

export default Carousel;
