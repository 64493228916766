import React, { FunctionComponent } from 'react';
import { Flex, Text, Box, Container } from '@qga/roo-ui/components';
import { SanityImage } from '../../../../../../../libs/shared/components/';

interface Props {
  valuePropositions: {
    name: string;
    image: string;
  }[];
  className: string;
}

const CVPBanner: FunctionComponent<Props> = ({
  valuePropositions,
  className,
}) =>
  valuePropositions && (
    <Container className={className} data-testid="CVPContainer" px="0" py="4">
      <Box bg="greys.porcelain" pt={[6, null, '0']}>
        <Flex
          justifyContent={'space-between'}
          flexDirection={['column', null, 'row']}
          width="100%"
          bg="white"
          px={['1', null, 0]}
        >
          {valuePropositions.map(({ name, image }) => (
            <Flex key={name} px="2" py="2" width={['100%', null, '100%']}>
              <Flex
                position="relative"
                height="48px"
                minWidth="48px"
                mt="2"
                justifyContent={['flex-start', null, 'center']}
              >
                <SanityImage
                  data-testid="SANITY_BACKGROUND_IMAGE"
                  image={image}
                  alt={`${name} icon`}
                />
              </Flex>
              <Flex alignItems="center">
                <Box pl="3">
                  <Text>{name}</Text>
                </Box>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Container>
  );

export default CVPBanner;
