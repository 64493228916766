const getInLength = (i, length) => {
  const offset = Math.abs(i % length);

  if (i >= 0) {
    return offset;
  }

  if (offset === 0) {
    return 0;
  }

  return length - offset;
};

export default getInLength;
