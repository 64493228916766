import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from '@emotion/styled';
import { height, width } from 'styled-system';

const StyledReactSwipeable = styled.div`
  ${height}
  ${width}
`;

const Swipeable = ({ onSwiping, onSwipedLeft, onSwipedRight, children }) => {
  const [swiping, setSwiping] = useState(false);

  const handleSwiping = (event) => {
    setSwiping(true);

    onSwiping(event);
  };

  const handleSwiped = (event) => {
    setSwiping(false);

    if (event.deltaX < 0) {
      onSwipedRight(event);
    }

    if (event.deltaX > 0) {
      onSwipedLeft(event);
    }
  };

  const handlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: handleSwiped,
    preventScrollOnSwipe: swiping,
  });

  return (
    <StyledReactSwipeable height="100%" width="100%" {...handlers}>
      {typeof children === 'function' ? children({ swiping }) : children}
    </StyledReactSwipeable>
  );
};

Swipeable.defaultProps = {
  onSwiping: () => {},
  onSwipedLeft: () => {},
  onSwipedRight: () => {},
};

Swipeable.propTypes = {
  onSwiping: PropTypes.func,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default Swipeable;
