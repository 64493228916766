import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, NakedButton } from '@qga/roo-ui/components';
import { left, right, style } from 'styled-system';
import withProps from '@/shared/components/withProps/withProps';

const Button = withProps({
  filter: ['drop-shadow(1px 1px 2px rgba(0,0,0,0.8))', null, 'none'],
  backgroundColor: ['transparent', null, 'rgba(0,0,0,0.1)'],
  p: '1',
  m: ['2', null, '4'],
})(styled(NakedButton)`
  border-radius: 50%;
  opacity: 0.8;

  ${style({ prop: 'filter' })}
  ${left} 
  ${right}

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    opacity: 1;
  }
`);

const Controller = ({ onPrevious, onNext }) => (
  <>
    <Button left="0" data-testid="PREVIOUS" onClick={onPrevious}>
      <Icon color="white" size={40} name="keyboardArrowLeft" />
    </Button>
    <Button right="0" data-testid="NEXT" onClick={onNext}>
      <Icon color="white" size={40} name="keyboardArrowRight" />
    </Button>
  </>
);

Controller.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Controller;
