import imageUrlBuilder from '@sanity/image-url';
import getClient from '../../getClient';

export default (() => {
  let builder;

  return function urlFor(source) {
    if (!builder) {
      const client = getClient();

      builder = imageUrlBuilder(client).auto('format');
    }

    return builder.image(source);
  };
})();
