import PropTypes from 'prop-types';
import RoomType from './RoomType';
import Property from './Property';
import { Property as DestinationProperty } from './Destination';

export default {
  ...Property,
  roomTypes: PropTypes.arrayOf(PropTypes.shape(RoomType)),
  ...DestinationProperty,
};
