import { useCallback, useRef, useState } from 'react';
import throttle from '@experiences-ui/shared/v2/utils/throttle';

const useThumbnailsEffect = () => {
  const [offset, setOffset] = useState(0);

  const handleResizeRef = useRef();

  const thumbnailsEffect = useCallback((el) => {
    if (!el) {
      const handleResize = handleResizeRef.current;
      window.removeEventListener('resize', handleResize);

      return;
    }

    const handleResize = throttle(() => {
      const outer = el.parentElement.offsetWidth;
      const inner = el.offsetWidth;

      if (inner < outer) {
        return;
      }

      setOffset((outer - inner) / (el.children.length - 1));
    });

    handleResize();
    window.addEventListener('resize', handleResize);

    handleResizeRef.current = handleResize;
  }, []);

  return {
    offset,
    ref: thumbnailsEffect,
  };
};

export default useThumbnailsEffect;
