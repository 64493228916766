import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Icon } from '@qga/roo-ui/components';
import { borders, color, space, themeGet, fontSize } from 'styled-system';

const Select = styled.select`
  appearance: none;
  text-overflow: ellipsis;
  background-color: transparent;

  &:focus {
    outline-color: ${themeGet('colors.brand.secondary')};
  }

  option {
    font-weight: normal;
    color: ${themeGet('colors.greys.charcoal')};
  }

  ${borders}
  ${color}
  ${fontSize}
  ${space}
`;

const IconWrapper = styled(Box)`
  pointer-events: none;
  transform: translate(0, -50%);
`;

const TertiarySelect = React.forwardRef(({ ...props }, ref) => (
  <Flex position="relative">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Select border="none" pr={6} {...props} ref={ref} />
    <IconWrapper position="absolute" top="50%" right={0}>
      <Icon name="expandMore" size={24} />
    </IconWrapper>
  </Flex>
));

export default TertiarySelect;
