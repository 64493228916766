import Image, { ImageLoader, ImageProps as NextImageProps } from 'next/image';
import React from 'react';
import urlFor from '../../sanity/image/urlFor';

interface SanityTransformationParams {
  [key: string]: string;
}
// Sanity Image Transformation URL parameters: https://www.sanity.io/docs/image-urls
const buildSanityParams = (params?: SanityTransformationParams) => {
  let result = '';
  if (!params) return '';

  for (const [key, value] of Object.entries(params)) {
    result += `&${key}=${value}`;
  }

  return result;
};

// https://nextjs.org/docs/pages/api-reference/components/image-legacy#layout
interface SanityImageProps extends Omit<NextImageProps, 'src'> {
  image: NextImageProps['src'];
  transformation?: SanityTransformationParams;
}

/*
  NOTE: next/image requires that layout is 'fill' if _no_ width & height are passed in.
  For backwards compatibility, it's kept as this, but we should consider changing it
  to it's default ('intrinsic'), and adding a width/height to existing images,
  as 'fill' will also set a default 'sizes' param of '100vw'.
  This means that any image without a width & height and with layout = 'fill', will have
  an image the size of the user's viewport downloaded.
  REF: https://nextjs.org/docs/pages/api-reference/components/image-legacy#sizes
*/
const SanityImage = ({
  image,
  layout = 'fill',
  transformation,
  // Sanity will automatically set a default quality of 75 if we don't pass one in.
  quality,
  ...props
}: SanityImageProps) => {
  const urlForLoader: ImageLoader = ({ width }) => {
    const params = buildSanityParams(transformation);
    return urlFor(image).width(width).quality(quality).url() + params;
  };

  const imgExists = !!urlFor(image)?.options?.source;
  if (!imgExists) return null;

  // When using next/image, a srcset is created with different images for different screen sizes.
  // This src image is used as a fallback, for browsers that do not accept srcset.
  const src = urlFor(image).url();

  return <Image {...props} loader={urlForLoader} src={src} layout={layout} />;
};

export default SanityImage;
