import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Flex, Icon, NakedButton } from '@qga/roo-ui/components';
import { opacity } from 'styled-system';
import Image from '@/shared/components/Image';
import getInLength from '../../utils/getInLength';
import throttle from '@experiences-ui/shared/v2/utils/throttle';
import { DumbCarousel as Carousel } from '../Carousel';
import Portal from '@experiences-ui/shared/v2/components/Portal';
import useThumbnailsEffect from './hooks/useThumbnailsEffect';

const Thumbnails = styled(Box)`
  display: inline-flex;
  transform: ${({ offset }) => `translateX(${offset}px)`};
  transition: transform 0.3s ease-out 0s;
`;

const Thumbnail = styled(NakedButton)`
  ${opacity};
`;

export const DumbLightbox = ({
  index,
  setIndex,
  onClose,
  images,
  thumbnails,
}) => {
  const { offset, ref } = useThumbnailsEffect();

  useEffect(() => {
    const handleKeyDown = throttle((event) => {
      switch (event.keyCode) {
        case 37: // LEFT
          setIndex((v) => v - 1);
          break;

        case 39: // RIGHT
          setIndex((v) => v + 1);
          break;

        default:
      }
    });

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setIndex]);

  return (
    <Portal selector="portal-root">
      <Global
        styles={{
          body: {
            overflow: 'hidden',
          },
        }}
      />
      <Flex
        position="fixed"
        zIndex="popup"
        top="0"
        bottom="0"
        right="0"
        left="0"
        bg="rgba(0,0,0,0.8)"
        flexDirection="column"
      >
        <Box flex="1" position="relative" m="4">
          <Carousel
            fit="contain"
            currentIndex={index}
            images={images}
            onNext={() => setIndex((v) => v + 1)}
            onPrevious={() => setIndex((v) => v - 1)}
          />
          <Box position="absolute" top="0" right="0">
            <NakedButton onClick={onClose}>
              <Icon name="close" color="white" size={36} />
            </NakedButton>
          </Box>
        </Box>
        <Box px="4" py="2" color="white" fontSize="md">
          {`${index + 1} / ${images.length}`}
          {images[index].caption && (
            <>
              <Icon name="circle" size={4} mx="2" />
              {images[index].caption}
            </>
          )}
        </Box>
        <Box backgroundColor="black">
          <Thumbnails
            data-testid="THUMBNAILS"
            offset={offset * index}
            ref={ref}
          >
            {thumbnails.map((image, i) => (
              <Thumbnail
                data-testid="THUMBNAIL"
                key={i} // eslint-disable-line react/no-array-index-key
                mx="1px"
                onClick={() => setIndex(i)}
                opacity={index === i ? 1 : 0.5}
              >
                <Image
                  height="90px"
                  width="90px"
                  fit="cover"
                  src={image.src}
                  alt={image.caption}
                />
              </Thumbnail>
            ))}
          </Thumbnails>
        </Box>
      </Flex>
    </Portal>
  );
};

DumbLightbox.propTypes = {
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
};

const Lightbox = ({ initialIndex, ...props }) => {
  const [index, setIndex] = useState(initialIndex);
  const { images } = props;
  const currentIndex = getInLength(index, images.length);

  return (
    <DumbLightbox
      index={currentIndex}
      setIndex={setIndex}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};

Lightbox.defaultProps = {
  initialIndex: 0,
};

Lightbox.propTypes = {
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
  initialIndex: PropTypes.number,
};

export default Lightbox;
