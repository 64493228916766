import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Box,
  Hide,
  ImageGallery as RooImageGallery,
  ImageGrid,
  MediaCounter,
  MediaCounterButton,
} from '@qga/roo-ui/components';
import getImageList from '@/shared/utils/getImageList';
import getInLength from '@/shared/utils/getInLength';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import { DumbLightbox } from '@/shared/components/Lightbox';
import ImagePropTypes from '../../propTypes/Image';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import dataLayer from '@/v2/utils/dataLayer';

const ImageGallery = ({
  images,
  altText,
  mediaCounterStyle = 'counter',
  layout = 'full',
  ...props
}) => {
  const [index, setIndex] = useState(0);
  const shouldUseNewEvents = useGA4Events();
  const currentIndex = getInLength(index, images.length);
  const imageList = getImageList(images, altText);

  const StyledMediaCounterButton = styled(MediaCounterButton)`
    position: absolute;
    right: 20px;
    bottom: 20px;
  `;

  const StyledMediaCounter = styled(MediaCounter)`
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    font-size: ${themeGet('fontSizes.xs')};
    font-weight: bold;
  `;

  const onImageChanged = (startIndex) => {
    setIndex(startIndex);
  };

  const gallery = (onClick) => (
    <Box data-testid="IMAGE_GALLERY" position="relative">
      {layout === 'full' ? (
        <RooImageGallery
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          images={imageList}
          onOpen={onClick}
          showIndex={false}
          showFullscreenButton={false}
          onImageChanged={onImageChanged}
          altText="Image Gallery"
        />
      ) : (
        <>
          <Hide xs sm>
            <ImageGrid
              images={imageList}
              layout={images.length < 5 ? 'full' : layout}
              onClick={onClick}
              height={'480px'}
              showMediaCounterButton={false}
            />
          </Hide>
          <Hide md lg>
            <RooImageGallery
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              images={imageList}
              onOpen={onClick}
              mainImageIndex={currentIndex}
              showFullscreenButton={false}
              onImageChanged={onImageChanged}
              altText="Image Gallery"
            />
          </Hide>
        </>
      )}
      {mediaCounterStyle === 'button' && (
        <StyledMediaCounterButton
          totalImages={imageList.length}
          variant="light"
          px={4}
          py={2}
          textStyles={{ fontSize: 'xs', fontWeight: 'bold' }}
          onClick={() => onClick(0)}
        />
      )}
      {mediaCounterStyle === 'counter' && (
        <StyledMediaCounter
          totalImages={imageList.length}
          variant="dark"
          px={2}
          selectedIndex={currentIndex + 1}
          onClick={() => onClick(0)}
        />
      )}
    </Box>
  );

  return (
    <ToggleContent
      toggle={(open) => {
        const onClick = (startIndex) => {
          if (shouldUseNewEvents) {
            dataLayer.galleryOpenEvent({ groupName: altText });
          }
          setIndex(startIndex);
          open();
        };

        return gallery(onClick);
      }}
      content={(close) => (
        <DumbLightbox
          index={currentIndex}
          setIndex={setIndex}
          images={images.map((image) => ({
            caption: image.caption,
            src: image.large,
          }))}
          thumbnails={images.map((image) => ({
            caption: image.caption,
            src: image.small,
          }))}
          onClose={close}
        />
      )}
    />
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)).isRequired,
  layout: PropTypes.oneOf(['full', '3-side', '4-side']),
  altText: PropTypes.string.isRequired,
  mediaCounterStyle: PropTypes.oneOf(['counter', 'button']),
};

export default ImageGallery;
