const getImageList = (images, altText) =>
  images.map(({ large, small, caption }, i) => ({
    src: large,
    alt: `${altText} - ${caption}`,
    thumbnail: small,
    index: i + 1,
    total: images.length,
  }));

export default getImageList;
