import { formatCurrency } from '@experiences-ui/shared/utils';
import React from 'react';

const amount = 25500;
const currency = formatCurrency(amount);

const Simple = () => (
  <>
    <div>This is a Simple Component</div>
    <div>
      The amount {amount} is converted to {currency} via the
      <code>@experiences-ui/utils</code> lib
    </div>
  </>
);

export default Simple;
