import { useState, useEffect } from 'react';
import { BREAK_POINTS_PX } from '../../lib/theme';
import throttle from '@experiences-ui/shared/v2/utils/throttle';

/**
 * @deprecated Use useBreakpoints.ts
 *
 * libs/shared/v2/hooks/useBreakpoints/useBreakpoints.ts
 *
 * import useBreakpoints from '@/libs/v2/hooks/useBreakpoints';
 */
const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState({
    xs: false,
    sm: false,
    md: false,
    lg: false,
  });

  useEffect(() => {
    const maps = {
      xs: [null, BREAK_POINTS_PX[0]],
      sm: [BREAK_POINTS_PX[0], BREAK_POINTS_PX[1]],
      md: [BREAK_POINTS_PX[1], BREAK_POINTS_PX[2]],
      lg: [BREAK_POINTS_PX[2], null],
    };

    const validateBreakpoints = () => {
      const width = window.innerWidth;

      const result = Object.keys(maps).reduce((prevValue, key) => {
        const value = maps[key];
        const [min, max] = value;

        const isMin = min ? width >= min : true;
        const isMax = max ? width < max : true;

        return {
          ...prevValue,
          [key]: isMin && isMax,
        };
      }, {});

      setBreakpoints(result);
    };

    const throttledValidateBreakpoints = throttle(validateBreakpoints);

    throttledValidateBreakpoints();

    window.addEventListener('resize', throttledValidateBreakpoints);

    return () => {
      window.removeEventListener('resize', throttledValidateBreakpoints);
    };
  }, []);

  return breakpoints;
};

export default useBreakpoints;
